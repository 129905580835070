/* eslint-disable import/no-named-as-default-member */
import Cookies from 'js-cookie';
import { initReactI18next } from 'react-i18next';

import translationDE from 'i18n/de.json';
import translationEN from 'i18n/en.json';
import translationFR from 'i18n/fr.json';
import translationIT from 'i18n/it.json';
import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';

const changeLanguage = (lng) => {
  i18n.changeLanguage(lng);
  document.documentElement.lang = lng;
};

const getLanguage = (t, value) => {
  switch (value?.toLowerCase()) {
    case 'de':
      return t('German');
    case 'fr':
      return t('French');
    case 'it':
      return t('Italian');
    case 'en':
      return t('English');
    default:
      break;
  }
};

const getTranslations = (lng) => {
  switch (lng) {
    case 'de':
      return translationDE;
    case 'fr':
      return translationFR;
    case 'it':
      return translationIT;
    case 'en':
      return translationEN;
    default:
      break;
  }
};

// Cookie used by B2B Portal for storing the language
const language = () => {
  const urlParams = new URLSearchParams(window.location.search);

  return (
    urlParams.get('lang') ||
    Cookies.get('EBC_user-locale') ||
    i18n.language ||
    'en'
  );
};

// load translation using xhr -> see /public/locales
// learn more: https://github.com/i18next/i18next-xhr-backend
i18n
  .use(Backend)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources: {
      de: {
        translations: translationDE,
      },

      en: {
        translations: translationEN,
      },

      fr: {
        translations: translationFR,
      },

      it: {
        translations: translationIT,
      },
    },

    debug: false,
    defaultNS: 'translations',
    fallbackLng: language(),
    fallbackNS: 'translations',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    keySeparator: false, // we do not use keys in form messages.welcome
    lng: language(),
    react: { wait: true },
    whitelist: ['de', 'en', 'fr', 'it'],
  });

export const getLanguageAsKey = (lng) => {
  const translations = getTranslations(lng);
  const translationsInverse = {};
  Object.keys(translations).forEach(
    (key) => (translationsInverse[translations[key]] = key)
  );
  return translationsInverse;
};

export default i18n;
export { getLanguage, changeLanguage, language };
